import { create } from 'apisauce';


const BaseApi = create({
    // baseURL: 'http://localhost:46405/api/',
    // baseURL: 'http://localhost:5000/api/',
//  baseURL: 'https://jbtapi.kmaschool.com/api/',
//  baseURL: 'https://apinewv2.lisoclasses.in/api/',
  baseURL: 'https://formapi.bseh.net/api/',


});

export default BaseApi;
