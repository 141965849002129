

import BaseApi from './BaseApi';




const GetMasterTypeDetails = () =>
  BaseApi.get(`Admin`);

const GetMasterTypedata = () =>
  BaseApi.get(`Admin/MasterType`);

// const PostMasterTypeDetails = (name,typeid,isactive) =>
// BaseApi.post("Admin/MastertypeDetail", { name: name,typeid:typeid,isactive:isactive});
const PostMasterTypeDetails = (form) => BaseApi.post("Admin/MastertypeDetail", form);
const UpdateMasterTypeDetails = (form) => BaseApi.post("Admin/updMastertypeDetail", form);

// const GetSchoolStudent = (user) =>
//   BaseApi.get(`School/GetSchoolStudent/` + user);

const Deletemastertype = (id) =>
  BaseApi.post("Admin/DeleteMastertype", { id: id });


const GetSchoolNotification = (id) =>
  BaseApi.get(`Admin/MasterDetail` + id);

const GetdistrictCollage = (id) =>
  BaseApi.get(`Admin/CollageList/` + id);


const GetDistrict = () =>
  BaseApi.get(`Admin/GetDistrict`);



const Getstudentlist = (id, classid) =>
  BaseApi.get(`College/StudentListGet/` + `${id}/${classid}`);

// rerunstudentlist
const Getrerunstudentlist = (id, classid, StudentType) =>
  BaseApi.get(`College/StudentListGetRerun/` + `${id}/${classid}/${StudentType}`);


// Permission setting
// const Permissioninsert = (Collageid, permission, isactive) =>
// BaseApi.post("Admin/Permissioncollagererun", {
//   33024: 33024,30: 30, 1: 1
// });
const Permissioninsert = (Collageid, permission, transactionType) =>
  BaseApi.post("Admin/Permissioncollagererun", {
    Collageid: Collageid, permission: permission, transactionType: transactionType
  });




// Permissiondetails
const Permissiondetails = (collegeid) =>
  BaseApi.get(`Admin/Permissiondetails/` + `${collegeid}`);


// -----------------------------Staff Insert---------------------
const Staffinsert = (name, emailId, mobileNo, username, password) =>
  BaseApi.post("Admin/InsertStaff", {
    name: name, emailId: emailId, mobileNo: mobileNo, username: username, password: password
  });

// -----------------------------Staff Details---------------------

const GetStaffDetails = () =>
  BaseApi.get(`Admin/Staffdetails`);


// -----------------------------Answer Insert---------------------


const Insertanswer = (studentid, userid, questionid, mark, answer1, answer2, answer3, answer4) =>
  BaseApi.post("Admin/InsertAnswer", {
    studentid: studentid, userid: userid, questionid: questionid, mark: mark, answer1: answer1, answer2: answer2,
    answer3: answer3, answer4: answer4
  });

// -----------------------------STUDENT Details---------------------
const GetstudentDetails = (id) =>
  BaseApi.get(`College/StudentDetails/${id}`);

// -----------------------------STUDENT studentDetailsbyrollno---------------------
const GetstudentDetailsbyrollno = (code) =>
  BaseApi.get(`Admin/StudentDetailsbyid/${code}`);
// -----------------------------STUDENT studentResult by roll no--------------------
const GetstudentResultbyrollno = (code) =>
  BaseApi.get(`Admin/StudentAnswerresult/${code}`);



const PostPermision = (Studentid) =>
  BaseApi.post(`Admin/InsertPermission/${Studentid}`);

// -----------------------------Answer Update--------------------


const InsertanswerUpdate = (studentid, userid, questionid, mark, answer1, answer2, answer3, answer4, optionA, optionB, optionC, optionD) =>
  BaseApi.post("Admin/InsertAnswerUpdate", {
    studentid: studentid, userid: userid, questionid: questionid, mark: mark, answer1: answer1, answer2: answer2,
    answer3: answer3, answer4: answer4,
    optionA: optionA, optionB: optionB, optionC: optionC, optionD: optionD
  }



  );

  const GetstudentAnswer= (studentId ) =>
  BaseApi.get(`Admin/GetStudentAnswer/${studentId }`);



export default {
  GetMasterTypeDetails,
  PostMasterTypeDetails,
  GetMasterTypedata,
  Deletemastertype,
  UpdateMasterTypeDetails,
  GetSchoolNotification,
  GetDistrict,
  GetdistrictCollage,
  Getstudentlist,
  Getrerunstudentlist,
  Permissioninsert,
  Permissiondetails,
  Staffinsert,
  GetStaffDetails,
  Insertanswer,
  GetstudentDetails,
  GetstudentDetailsbyrollno,
  PostPermision,
  GetstudentResultbyrollno,
  InsertanswerUpdate,
  GetstudentAnswer
}