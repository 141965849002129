import React, { useState, useEffect, useContext } from 'react'
import AttendenceApi from '../../../API/AttendenceApi';
import SchoolDashboardApi from '../../../API/SchoolDashboardApi';
import { Provider } from '../../../Context/Appcontext';
import { toast } from 'react-toastify';
import StudentDetail from '../../../API/StudentDetail';

export default function Studentlist({ ...props }) {


    const context = useContext(Provider);

    const notify = () => toast.success('Sucessfully Inserted!', { autoClose: 1000 })
    const errormsg = (msg = "Roll No Already Exists !") => toast.error(msg, { autoClose: 1500 })

    const [StudentList, setStudentList] = useState([]);
    const [StudentAttendence, setStudentAttendence] = useState([]);
    const [attDate, setattDate] = useState();
    const [Attendence, setAttendence] = useState();
    const [MarkAtt, setMarkAtt] = useState(false);
    const [today, setToday] = useState();
    const [Studentdata, setStudentdata] = useState();
    const [SelectedId, setSelectedId] = useState('');

    var current = new Date();
    var dd = current.getDate();
    var mm = current.getMonth() + 1; //January is 0!
    var yyyy = current.getFullYear();

    if (dd < 10) {
        dd = '0' + dd;
    }

    if (mm < 10) {
        mm = '0' + mm;
    }

    current = yyyy + '-' + mm + '-' + dd;



    useEffect(() => {
        handleStudentdata();
        StudentDetails();
        // setToday(date)
    }, [1000]);

    const handleStudentdata = async () => {
        const resp = await context.GetSchoolStudentList(context.user, 1);
        if (resp) {
            setStudentList(resp);
            
            console.log("jery",resp)
            console.log("tom",context.user)

        }
    };

    const handleattendance = (attDatea) => {
        AttendenceApi.GetAttendence(context.user, attDatea, 1).then((resp) => {
            if (resp.ok) {
                let Data = resp.data;
                setAttendence(Data);
                //console.log("Atten........===>", Data);
                setMarkAtt(true)
            }
        });
    };
// Student Details
    const StudentDetails =  (SelectedId) => {
        // console.log('sushil',SelectedId)

        StudentDetail.GetStudentDetails(SelectedId).then((resp) => {
            if (resp.ok) {
                let Data = resp.data;
                printDiv(Data);
            }
        });
    };



    const handleChange = (e) => {
        var index = StudentAttendence.findIndex(o => o.studentid === e.target.name);

        if (index !== -1) StudentAttendence.splice(index, 1);

        const newData = [...StudentAttendence, { studentid: e.target.name, attendence: e.target.value }]
        setStudentAttendence(newData)
        console.log("aatt", newData)

    }
    const handledate = async (e) => {
        const newdate = await e.target.value
        await setattDate(newdate)
        handleattendance(newdate)
    }



    const handleSubmit = (evt) => {
        evt.preventDefault()
        StudentAttendence.forEach((element) => {
            AttendenceApi.StudentAttendencee(
                element.studentid, context.user, element.attendence, current, 1
            )
                .then((resp) => {
                    if (resp.ok) {
                        let Data = resp.data;
                        if (Data == "Marked Successfully !") {
                            notify()

                        }
                        else {
                            errormsg()
                        }


                    } else {
                        if (resp.status == 409) {

                            errormsg(resp.data)
                        }
                    }
                })
                .catch((err) => console.log(err));
        });
    }
    function printDiv(Studentdata) {

        
        //Get the HTML of div
        // var divElements = document.getElementById("tblPrintDiv").innerHTML;
        //Get the HTML of whole page
        var oldPage = document.body.innerHTML;
        //Reset the page's HTML with div's HTML only
        document.body.innerHTML = 
          `<html><head><title></title>
          <!DOCTYPE html>
<html>
<body>
<h1 style=" text-align: center;">${Studentdata.collegeName}</h1><br><h3 style="background-color:powderblue;text-align: center; ">Reconized by NCTE-JAIPUR & AFF To MDU Rohtak  </h3><br>
<h4 style="background-color:powderblue; text-align: center; ">Vpo, Zainabad , Distt,Rewari(Hr.)  </h4><br>
<h4 style="background-color:powderblue;padding ">Reg no :  </h4><br>
<h4 style="background-color:powderblue;padding ">Date :  </h4><br>
<p>TO</p><br>
<p>The DEED : </p><br>
<p>Rewari </p>
<p>Subject : Regarding Permission for school Internship Programe for D.EI.Ed 2nd year Student (${Studentdata.sessionName}).
<p>R/Sir</p>
<p>1 =  We have been seeking your cooperation for conducting school internship prpgrME as per HBSE Letter No 200/Acadmic dated 09.04.2022 for seven weeks (From 13th April 2022 to onwards ) for our D.I.E.D 2nd year students Through your school by providing Mentor to our students</p>
<p>2 =  Kindly accord the permission for our D.IE.Ed students as following:</p>
<style>
          table {
            font-family: arial, sans-serif;
            border-collapse: collapse;
            width: 100%;
          }
          
          td, th {
            border: 1px solid #dddddd;
            text-align: left;
            padding: 8px;
          }
          
          tr:nth-child(even) {
            background-color: #dddddd;
          }
          </style></head><body> 
          <table>
  <tr>
    <th>Roll NO</th>
    <th>Reg NO</th>
      <th>Name</th>
      <th>Class</th>

  </tr>
  <tr>
    <td>${Studentdata.rollNo}</td>
    <td>${Studentdata.enrollmentNo}enrollmentNo</td>
    <td>${Studentdata.studentName}</td>
     <td>${Studentdata.className}</td>
  </tr>

</table>
<p>Please accept and oblige the same. </p>
<p>Thank You </p><br><br><br><br><br><br><br>
<h3>Priciple  </h3><br>
<h2 style="background-color:powderblue;padding ">${context.UserDetail.name} </h2><br>
<h3>${context.UserDetail.locationName}  </h3>


</body>
</html>


          `+  
          "</body>";
        //Print Page
        window.print();
        //Restore orignal HTML
        document.body.innerHTML = oldPage;
        window.location.reload(false);
    }


    return (
        <>
            <div className="page-section">
                <h1 className="text-display-1">Student List</h1>
            </div>


            <form onSubmit={handleSubmit} >
                {StudentList && StudentList.length > 0 ? (
                    <div className="panel panel-default paper-shadow" data-z="0.5">
                        <div className="panel-heading">
                            <div className=" form-group daterangepicker-report" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <div className="form-control max-width-300">
                                    <i className="fa fa-calendar fa-fw" />
                                    <span><strong>Student List (Today : {current})</strong></span>
                                    <b className="caret" />
                                </div>
                                {/* <div className=" max-width-300">
                                    <input type="date" className="form-control" onChange={handledate}
                                        required
                                        min={current}
                                        max={current} />
                                </div> */}
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-hover table-outline  mb-0 ">
                                <thead >
                                    <tr>
                                        <th className="text-center">Sr.No</th>
                                        <th className="text-center">StudentCode</th>
                                        <th className="text-center">Roll No</th>
                                        <th className="text-center">Student Name</th>
                                        <th className="text-center">Father Name</th>
                                        <th className="text-center">Mark </th>
                                        <th className="text-center"> </th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {StudentList.map((data, i) => (
                                        <tr key={data.uentityId}>
                                            <td className="text-center">{i + 1}</td>
                                            <td className="text-center">{data.studentid}</td>
                                            <td className="text-center">{data.rollNo}</td>
                                            <td className="text-center"><strong>{data.studentName}</strong></td>
                                            <td className="text-center">{data.fatherName}</td>
                                            <td className="text-center">
                                                {/* <button  className="btn btn-info  mr-4" style={{ float: 'right', }}
                                                onClick={() => printDiv()}
                                                >Print Details</button> */}
                                                <button  className="btn btn-info  mr-4" style={{ float: 'right', }}
                                                onClick={() => StudentDetails(data.uEntityId)}
                                                >Print Details</button>
                                            </td>



                                            {/* <>



                                                <td className="text-center ">
                                                    <input
                                                        type="radio"

                                                        value="Present"
                                                        name={data.studentid}
                                                        onChange={handleChange}
                                                        checked={data.attendence == "Present" ? true : null}
                                                        disabled={data.attendence != "" ? true : false}
                                                    // required
                                                    /> <strong style={{ color: "green" }}>Present</strong>
                                                </td>
                                                <td className="text-center ">
                                                    <input

                                                        type="radio"
                                                        value="Absent"
                                                        name={data.studentid}
                                                        onChange={handleChange}
                                                        checked={data.attendence == "Absent" ? true : null}
                                                        disabled={data.attendence != "" ? true : false}
                                                    // required
                                                    /> <strong style={{ color: "red" }}>Absent</strong>

                                                </td>


                                            </> */}



                                        </tr>
                                    ))}

                                </tbody>
                            </table>

                        </div>
                        <div className="panel-footer">
                            {/* <button type='submit' className="btn btn-primary mr-5" style={{ float: 'right', }} >Submit</button> */}
                            <strong className='ml-5' style={{ color: "red" }}>Once Submited It Will Not Changed *</strong>
                        </div>
                    </div>
                ) :
                    <center><h3>Student Not Available</h3></center> //<BeatLoader color="blue" loading />
                }

            </form>


        </>
    )
}
