import React from 'react';

const Footer = () => {
    return (
        <footer className="footer">
            <strong>Learning</strong> v1.1.0 &copy;
        </footer>
    );
};

export default Footer;