import React, { Component } from 'react'
import BaseApi from '../API/BaseApi';
import LoginApi from '../API/LoginApi';
import jwt_decode from "jwt-decode";
import SchoolDashboardApi from '../API/SchoolDashboardApi';


const Provider = React.createContext();

class AppContext extends Component {
    state = {
        user: null,
        userType: null,
        loading: true,
        UserDetail: [],
        collage:[],
    }

    async componentDidMount() {
        await this.defaultFunction();
        this.setState({ loading: false })
    }

    defaultFunction = async () => {
        const token = localStorage.getItem("usertoken")
        if(token){
            var decoded = jwt_decode(token);
            await this.setState({ user: decoded.unique_name, userType: decoded.role })
            await BaseApi.setHeader('Authorization', 'Bearer ' + token)

        //     if(decoded.role == "District"){
        //         this.props.navigate('/diet')
        //     }
        //    else if(decoded.role == "School"){
        //         this.props.navigate('/school')
        //     }
        }
        else{
            this.props.navigate('/')
        }

      

        if (this.state.user != null) {
           await  this.getUserDetail();
            this.GetStudentList();
        }
    }

    userCheck = async (userCode) => {
        // const resp = await LoginApi.UserCheck(userCode);

        // if (resp.ok) {
        //     return resp.data.status ? true : false
        // }

        // return false
    }

   


    loginFunc = async (username,password) => {
        const resp = await LoginApi.Login(username,password);
        if (resp.ok) {
            if (resp.data == "InvalidUser") {
                return false;
            }
            else {
                localStorage.setItem('usertoken', resp.data.token)
                var decoded = jwt_decode(resp.data.token);

                await this.defaultFunction()

                if(decoded.role == "School"){
                    this.props.navigate('/school')
                    alert("login")
                    return true
                }
                else if(decoded.role == "District"){
                    this.props.navigate('/diet')
                    alert("login")
                    return true
                }
                else if(decoded.role == "admin"){
                    this.props.navigate('/admin')
                    alert("Admin login")
                    return true
                }
                else if(decoded.role == "Staff"){
                    this.props.navigate('/Staff')
                    alert("Staff login")
                    return true
                }

                console.log(decoded)
                return false;
            }
        }

        return false
    }

    logoutFunc = () => {
        localStorage.removeItem("usertoken");
        this.setState({ user: null, userType: null })
        this.props.navigate('/')
        return true
    }


    getUserDetail = async() => {
        const resp = await LoginApi.UserdetailApi(this.state.user);

        if(!resp.ok){
            console.log("getUserDetail...Error", resp.originalError);
            return
        }

        this.setState({UserDetail: resp.data});
    }

    GetStudentList = () => {
        SchoolDashboardApi.GetDashboard(this.state.user).then(
          (resp) => {
            if (resp.ok) {
              let Data = resp.data;
              // console.log("collage D........", Data);
              this.setState({ collage: Data });
            }
          }
        );
      };


      GetSchoolStudentList = async(id = this.state.user,Atttype) => {
        const resp = await SchoolDashboardApi.GetSchoolStudent(id,Atttype);

        if(!resp.ok){
            console.log("GetSchoolStudentList...error", resp.originalError)
            return;
        }

        return resp.data;
      };


    render() {
        return (
            <Provider.Provider value={{
                ...this.state,

                loginFunc: this.loginFunc,
                logoutFunc: this.logoutFunc,
                GetSchoolStudentList: this.GetSchoolStudentList,
            }}>
                {this.props.children}
            </Provider.Provider>
        )
    }
}



export { AppContext, Provider };
