// import { BaseApi } from "./Baseurl";

import BaseApi from './BaseApi';




const   GetDistrictschool= (Locationid) =>
BaseApi.get(`School/GetDistrictSchool/` + Locationid);


// const GetSchoolStudent = (user) =>
//   BaseApi.get(`School/GetSchoolStudent/` + user);

  export default {
    GetDistrictschool,
  }