// import { BaseApi } from "./Baseurl";
import BaseApi from './BaseApi';


const   GetDashboard= (uEntityId) =>
BaseApi.get(`College/CollegeDetail/` + uEntityId);


const GetSchoolStudent = (user,Atttype ) =>
  BaseApi.get(`School/GetSchoolStudent/${user}/${Atttype }`); 

  export default {
    GetDashboard,
    GetSchoolStudent,
  }