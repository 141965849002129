// import { BaseApi } from "./Baseurl";
import BaseApi from './BaseApi';


const Login = (username, password) =>
BaseApi.post("Home/SchoolLoginnew", { userCode: username, password: password});


const UserdetailApi = (uEntityId ) =>
  BaseApi.get(`Home/UserDetails/` + uEntityId);


  export default {
    Login,
    UserdetailApi,

  }