import React, { useContext } from 'react'
import { Form, Formik } from "formik";
import * as Yup from "yup";
import "../../assets/Loginstyle.css"
import { Provider } from '../../Context/Appcontext';
// import Login from '../../API/LoginApi';

const validationSchema = Yup.object().shape({
  username: Yup.string().required("Please enter your Username!"),
  password: Yup.string().required("Please enter your Password!"),
});

export default function Login() {
  const context = useContext(Provider);


  const loginFunc = async (values, resetForm) => {
    const resp = await context.loginFunc(values.username, values.password);

    if (!resp) {
      alert("Invalid username and password.")
      return
    }

    resetForm();
  }

  return (
    <div>

      <div className="limiter">
        <header>
          <div className="row">
            <div className="logo">
              {/* <img src="https://adminsip.bseh.net/static/media/bhivani.a3fd2726d067ba8ff278.jpg" style={{ width: '100%' }} /> */}
              <img src="https://school.bseh.net/assets/img/Logo_bhiwani.png" style={{ width: '100%' }} />
            </div>
            <div className="detail_content">
              <h3 className="mt-3">
                SIP DED Education Haryana
                {/* राष्ट्रीय साधन व योग्यता छात्रवृत्ति परीक्षा (NMMS) 2021-22 */}
              </h3>
              {/* <h6>एस. सी.ई.आर.. हरियाणा, गुरुग्राम </h6> */}
            </div>
            {/*<div class="right_header">
                                <a href="https://school.bseh.net/" class="btn btn_register">Already Register</a>
                            </div>*/}
          </div>
        </header>
        <div className="new_register" style={{ textAlign: 'center' }}>
          <span className="h5" style={{ backgroundColor: 'yellow' }}>

          </span>
          <br /><br />
        </div>
        <div className="container-login100">
          <div className="row row_forcontent">
            {/* <div className="col-md-6 bright">
                <div className="detail_content">
                </div>
                <div className="text_login">
                  <div className>
                    <div className="links_use">
                      <ul>
                        <li>
                          <a>
                            <i className="fa fa-hand-o-right " />
                            राष्ट्रीय साधन व योग्यता छात्रवृत्ति परीक्षा मानव संसाधन विकास मंत्रालय नई
                            दिल्ली द्वारा चलाई जा रही है जिसका उद्देश्य सरकारी /अनुदान प्राप्त
                            विद्यालयों में पढ़ने वाले प्रतिभाशाली गरीब बच्चों का चयन कर उनका शैक्षिक
                            विकास करना हैं ! इस योजना के तहत पूरे प्रदेश में से 2337 पात्र विद्यार्थियों
                            का चयन करने हेतु परीक्षा आयोजित की जाती है।छात्रवृत्ति 1000 रु प्रतिमाह
                            कक्षा IX, X, XI व XII के लिए
                          </a>
                        </li>
                        <li>
                          <a>
                            <i className="fa fa-hand-o-right " />
                            1. इस परीक्षा में बैठने हेतु विद्यार्थी राज्य के सरकारी /अनुदान प्राप्त
                            विद्यालयों में आठवीं कक्षा में पढ़ रहे हों तथा उन्होंने सातवीं कक्षा की
                            परीक्षा इन्ही विद्यालयों से पास की हो। परीक्षार्थी के माता -पिता की सभी
                            स्रोतों से कुल वार्षिक आय 1,50,001 रु0 से कम होनी चाहिए।
                          </a>
                        </li>
                        <li>
                          <a>
                            <i className="fa fa-hand-o-right " />
                            2. इस परीक्षा में जिला स्तर पर चयनित विद्यार्थियों को जिले के कोटे के अनुसार
                            छात्रवृत्ति दी जाएगी। उपरोक्त परीक्षा में बी0 सी0 ए0 वर्ग को 16 प्रतिशत, बी0
                            सी0 बी0 वर्ग को 11 प्रतिशत, एस0 सी0 वर्ग को 20 प्रतिशत एवं शारीरिक विकलांग
                            वर्ग को 4 प्रतिशत आरक्षण दिया गया है। शेष सामान्य है। आरक्षित वर्ग से आवेदन
                            करने वाले विद्यार्थियों को सक्षम अधिकरियो द्वारा जारी प्रमाण -पत्र की
                            छायाप्रति <strong>Online</strong> आवेदन पत्र के साथ <strong> Upload
                            </strong> करनी होगी।
                          </a>
                        </li>
                        <li>
                          <a>
                            <i className="fa fa-hand-o-right " />
                            <h6>
                              प्रमाण -पत्र की छायाप्रति <strong>Online</strong> आवेदन पत्र के साथ
                              <strong> Upload </strong>करनी होगी।
                              परीक्षा हेतु <strong> Online </strong> आवेदन पत्र हरियाणा शिक्षा बोर्ड,
                              भिवानी की वेबसाइट <strong> www.bseh.org.in </strong> व वेबसाइट
                              <strong>scertharyana.gov.in </strong> पर भी भर सकते है।
                            </h6>
                          </a>
                        </li>
                        <hr/>
                      </ul>
                    </div>
                    <br />
                    <br />
                    <div className="table table-responsive" style={{ marginTop: '-15px' }}>
                      <div className="table table-responsive">
                        <table className="table table-bordered" cellSpacing={0} rules="all" border={1} id="grdLateFee" style={{ width: '100%', borderCollapse: 'collapse' }}>
                          <tbody><tr>
                            <td>
                              <span id="grdLateFee_lblFromDate_0">
                                Online आवेदन पत्र भरना National Means-
                                cum-Merit Schoarship-NMMS- राष्ट्रीय साधन
                                व योग्यता छात्रवृत्ति परीक्षा
                              </span>
                            </td>
                            <td>
                              <span id="grdLateFee_lblToDate_0">10 फरवरी 2022 से प्रारम्भ</span>
                            </td>
                          </tr>
                            <tr>
                              <td>
                                <span id="grdLateFee_lblFromDate_0">
                                  Online आवेदन भरने की अन्तिम तिथि National Means-
                                  cum-Merit Schorship-NMMS- राष्ट्रीय साधन व योग्यता
                                  छात्रवृत्ति परीक्षा
                                </span>
                              </td>
                              <td>
                                <span id="grdLateFee_lblToDate_0"> 2 मार्च 2022</span>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <span id="grdLateFee_lblFromDate_0">
                                  National Means-cum-Merit Schorship-NMMS-
                                  राष्ट्रीय साधन व योग्यता छात्रवृत्ति परीक्षा का आयोजन
                                </span>
                              </td>
                              <td>
                                <span id="grdLateFee_lblToDate_0"> 20 मार्च 2022(रविवार ) </span>
                              </td>
                            </tr>
                          </tbody></table>
                      </div>
                    </div>
                    <div className="links_use">
                      <ul>
                        <li>
                          <a>
                            <i className="fa fa-hand-o-right " />
                            <strong>अधिक जानकारी के लिए संपर्क करे।</strong>
                          </a>
                        </li>
                        <li>
                          <a>
                            <i className="fa fa-hand-o-right " />
                            फ़ोन नं0 0124-231981 /8810439062 (केवल कार्य दिवस पर 9:00 AM to 5:00 PM )
                            अधिक जानकारी के लिए विवरण पुस्तिका Prospectus हमारी वेबसाइट
                            scertharyana.gov.in पर 01 मार्च 2022 से उपलब्ध होगा।
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div> */}
            <div className="col-md-12">
              <div className="login_section">
                <div className="wrap-login100 selector">
                  <div className="login_section">
                    <div className="wrap-login100">
                      <Formik
                        initialValues={{
                          username: "",
                          password: "",
                        }}
                        // validationSchema={validationSchema}
                        onSubmit={(values, { resetForm }) => {
                          loginFunc(values, resetForm)
                        }}

                      >
                        {({ errors, touched, values, handleChange, handleBlur }) => (

                          <Form className="login100-form validate-form" >
                            <span className="login100-form-title p-b-34 p-t-27">
                              Login
                            </span>
                            <h5 style={{ color: 'red', textAlign: 'center' }} />
                            <h3 style={{ color: 'red', textAlign: 'center' }} />
                            <div className="wrap-input100 validate-input" data-validate="Enter Code">
                              <input
                                type="text"
                                name="username"
                                id="username"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.username}
                                className="input100"
                                placeholder="UserCode"
                              />
                              {/* {errors.username && touched.username ? (
                                                        <div style={{ color: "red" }}>{errors.username}</div>
                                                    ) : null} */}
                            </div>

                            <div className="wrap-input100 validate-input" data-validate="Enter password" id="pwddiv">

                              <input
                                type="password"
                                name="password"
                                id="password"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.password}
                                className="input100"
                                placeholder="Password"
                              />
                              {/* {errors.password && touched.password ? (
                                                        <div style={{ color: "red" }}>{errors.password}</div>
                                                    ) : null} */}

                            </div>
                            <div className="container-login100-otp-btn">
                            </div>
                            {/* <div className="text-danger">{this.props.errorMsg}</div> */}
                            <div className="container-login100-otp-btn" id="loginButton">
                              <button className="login100-form-btn" type="submit">
                                Login
                              </button>
                            </div>
                          </Form>

                        )}

                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}
