import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom'
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import MasterTypeApi from '../../../API/MasterTypeApi';
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom'

const StudentResult = () => {

    const [Data, setData] = useState([]);

    useEffect(() => {
        // Getdata();
    }, []);

    const { id } = useParams();
    const notify = () => toast.success('Sucessfully Inserted!')
    const errormsg = (Data) => toast.error(Data
        , {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });


    //   get data function
    const Getdata = (values, resetForm) => {
        MasterTypeApi.GetstudentResultbyrollno(

            values.Code

        )
            .then((resp) => {
                if (resp.ok) {
                    let Data = resp.data;
                    setData(Data);
                    resetForm();
                    console.log("Student  dtails........===>", Data);
                    // console.log('hi sunny',context.UserDetail.locationId)
                }
            });
    };



    //   post data function
    const postPermision = (resetForm) => {
        console.log("helo", Data.studentId)

        MasterTypeApi.PostPermision(
            Data.studentId
        )

            .then((resp) => {
                // console.log("id",id)

                if (resp.ok) {
                    let Data = resp.data
                    if (Data == "updated") {
                        // Getdata();
                        notify();
                        // resetForm();

                        // Getdata();
                    }
                    else {
                        // resetForm();
                        // Getdata();
                        errormsg(Data);

                    }
                }
            })
            .catch((err) => console.log(err));


    };


    return (
        <>
            <div className="card border-warning mb-3">
                <div className="card-header bg-info text-white">Enter Student</div>
                {/* <div className="card-title text-danger ml-3 mt-3">All Fields Are Mandatory *</div> */}
                <div className="card-body ">
                    <Formik
                        initialValues={{

                            Code: ''

                        }}

                        onSubmit={(values, { resetForm }) => {
                            // same shape as initial values
                            // isPost ? postData(values, resetForm) : putData(values, resetForm);
                            Getdata(values, resetForm);
                        }}
                    // validationSchema={validationSchema}

                    >
                        {({ errors, touched, values, handleChange, handleBlur }) => (
                            <Form>
                                <div className="row">

                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label htmlFor="Code">Roll Number</label>
                                                <input type="text"
                                                    className="form-control"
                                                    placeholder="Enter Roll Number"
                                                    name="Code"
                                                    id="Code"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.Code}
                                                />
                                                {/* {errors.Name && touched.Name ? (
                                                <div style={{ color: "red" }}>{errors.Name}</div>
                                            ) : null} */}
                                            </div>
                                        </div>

                                    </div>





                                </div>



                                {/* <button type="submit" className="btn btn-primary">Submit</button> */}
                                <button type="submit" className="btn btn-primary "> Search Student </button>
                            </Form>
                        )}
                    </Formik>
                </div>

            </div>
            <br></br>

            <div className="table-responsive">
                <table className="table table-hover table-outline  mb-0 ">
                    <thead >
                        <tr>
                            <th className="text-center">Name</th>
                            <th className="text-center">Mobile No</th>
                            <th className="text-center">Class Name</th>
                            <th className="text-center">Max Marks</th>
                            <th className="text-center">Obtain Marks</th>
                            <th className="text-center">Student Form Check</th>



                            {/* <th className="text-center">Attendence </th> */}

                        </tr>
                    </thead>

                    <tbody>
                        {/* {Data && Data.length > 0 ? ( */}

                            <tr >
                                {/* <td className="text-center">{i + 1}</td> */}
                                <td className="text-center">{Data.studentName}</td>
                                <td className="text-center">{Data.mobileNo}</td>
                                <td className="text-center">{Data.className}</td>
                                <td className="text-center">{Data.maxMarks}</td>
                                <td className="text-center">{Data.obtainMarks}</td>
                         
                                {/* <td className="text-center">   <button className="btn btn-danger  mr-3" style={{ float: 'right', }}
                                onClick={() => postPermision(Data.studentId)}
                            >Assign Permmion</button>

                              

                            </td> */}
                                {/* <td className="text-center">
                                    {Data.isformfill
                                        ? 'Form Filled'
                                        : 'Form not Filled'}
                                </td> */}
                                <td className="text-center">
                                    <button disabled={Data.isformfill == 1 ? true : false} type="button" className="btn btn-primary"><Link to={`/Staff/StudentFormUpdate/${Data.uentityId}`}>Student Form Check</Link></button>
                                </td>





                            </tr>
                        {/* ) : <center><h3>Student Not Available</h3></center>
                        } */}

                    </tbody>
                </table>

            </div>


        </>

    );
}

export default StudentResult;
